@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '../app-base';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '../../css/muli.css';

#main {
    background-image: url("../images/background.jpg");
}

.main-button {
    background-color: #feb003;
    border-color: #E3A112;
}

.main-button:hover {
    background-color: #E3A112;
    border-color: #feb003;
}

#logo img {
    width: 200px;
}
