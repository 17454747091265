/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 18, 2021 */
@font-face {
    font-family: 'muliregular';
    src: url('Muli-Regular.ttf') format('truetype'),
    url('muli-regular_kopie-webfont.woff2') format('woff2'),
    url('muli-regular_kopie-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'mulibold';
    src: url('Muli-Bold.ttf') format('truetype'),
    url('muli-bold_kopie-webfont.woff2') format('woff2'),
    url('muli-bold_kopie-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'muliblack';
    src: url('Muli-Black.ttf') format('truetype'),
    url('muli-black_kopie-webfont.woff2') format('woff2'),
    url('muli-black_kopie-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'muliitalic';
    src: url('Muli-Italic.ttf') format('truetype'),
    url('muli-italic_kopie-webfont.woff2') format('woff2'),
    url('muli-italic_kopie-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



