// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Border
$border-color: #7f9b90;

// Scan body text
$text-color: #4B6B5D;

// Scan text in colored background
$text-bg-color: #4B6B5D;

//checkmark score, th background, button-background on hover
$dark-color: #668377;

//border, button-background
$medium-color: #7f9b90;

// td background
$light-color: #C0CCC9;

